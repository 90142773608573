import React from "react";
import {Link} from "react-router-dom";
 
const Navbar = () => {
    return (
        <div className="d-flex align-items-center gap-4 | px-5 py-3">
          <Link to="/">
            <img className='logo' src="/cfcalc-logo.png" alt=""></img>
          </Link>
          <Link className="p-2 | navlink" to="/">
              Home
          </Link>
          <Link className="p-2 | navlink" to="/about">
              About
          </Link>
        </div>
    );
};
 
export default Navbar;