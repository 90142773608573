import React from 'react';
import TableRow from '../TableRow/TableRow.jsx'


const Table = ( {tblData, id, key, state, setState, emissionFunc, energyFunc, totalEmissions} ) => {

  var tableEmissions = emissionFunc();
  var tableEnergy = energyFunc != null ? energyFunc() : null;

  const energyRow = () => {
    if(tableEnergy != null) {
      return (
        <tr className="data-row bg-secondary" id={id + 'energy-data-row'}>
          <td className='bg-secondary border fw-bold'>{tblData.name + ' Energy'}</td>
          <td className='bg-secondary border fw-bold' id={id + 'energy-result'} colSpan='2' >{tableEnergy.toFixed(2) + ' '} 
            <span id={id + 'energy-result-units'}>
              {tblData.table_energy_units}
            </span>
          </td>
        </tr>
      )
    }
    else {
      console.log("no energy row");
      return null;
    }
  }

  return(
    <div className='card'>
    <h3 className='table-title p-3 bg-primary-subtle' key={key}>{tblData.name}</h3>
    <table className="table table-fixed w-100 mb-0" id={id} key={key}>
      <thead><tr>
        <th scope="col"></th>
        <th scope="col"></th>
        <th scope="col">Notes</th>
      </tr>
      </thead>
      <tbody>
      {
        tblData.rows.map((row, i) => {
          return (
            <TableRow 
              rowData={row} 
              key={row.field_id + '_' + i} 
              state={state} 
              setState={setState}
            />
          )
        })
      }
      { energyRow() }
      <tr className="data-row bg-secondary" id={id + 'emission-data-row'}>
        <td className='bg-secondary border fw-bold'>{tblData.name + ' Emissions'}</td>
        <td className='bg-secondary border fw-bold' id={id + 'result'} colSpan='2' >{tableEmissions.toFixed(2)} mt CO2e/yr</td>
      </tr>
      <tr className="data-row">
        <td className='bg-secondary border fw-bold'>Percentage of total Carbon Footprint</td>
        <td className='bg-secondary border fw-bold' colSpan='2'>{(tableEmissions/totalEmissions * 100).toFixed(2)}%</td>
        
      </tr>
      </tbody>
    </table>
    </div>
    
  )
}

export default Table;
