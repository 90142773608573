import React from "react";
import Table from "../Table/Table.jsx";

const directHtmlObj = require("../../data/DirectTableData.json");

const DirectCalculator = ({
  directVars,
  setDirectVars,
  heatingEmissionFunction,
  heatingEnergyFunction,
  electricEmissionFunction, 
  electricEnergyFunction, 
  transportationEmissionFunction,
  transportationEnergyFunction,
  totalEmissions,
}) => {

  return (
    <div className="direct-calculator container grid">
      <h2 className="underline">{directHtmlObj.title}</h2>
      <p className="text-start py-3">
        Direct emissions address the energy that we purchase and use directly such as home natural gas, electricity and gasoline.
      </p>
      {directHtmlObj.tables.map((tblData, index) => {

        var emissionFunc;
        var energyFunc;
        switch( tblData.table_id ){
          case "space-heating-and-natural-gas" :
            emissionFunc = heatingEmissionFunction;
            energyFunc = heatingEnergyFunction;
            break;
          case "home-electric" : 
            emissionFunc = electricEmissionFunction;
            energyFunc = electricEnergyFunction;
            break;
          case "transportation" :
            emissionFunc = transportationEmissionFunction;
            energyFunc = transportationEnergyFunction;
            break;
          default:
            emissionFunc = heatingEmissionFunction;
            energyFunc = null;
        }

        return (
          <div className="mt-3">
          <Table
            tblData={tblData}
            id={tblData.table_id}
            key={tblData.table_id}
            state={directVars}
            setState={setDirectVars}
            emissionFunc={emissionFunc}
            energyFunc={energyFunc}
            totalEmissions={totalEmissions}
          />
          </div>
        );
      })}
    </div>
  );
};

DirectCalculator.propTypes = {};

DirectCalculator.defaultProps = {};

export default DirectCalculator;
