import React from 'react';
import Select from '../Select/Select.jsx'
import Input from '../Input/Input.jsx'
import Modal from '../Modal/Modal.jsx';
import ModalButton from '../Modal/ModalButton.jsx';


const TableRow = ({ rowData, state, setState }) => {
  
  let tableRowInteractiveEl;

  if(rowData.element === 'select'){
    tableRowInteractiveEl = 
    <Select 
      attrs={rowData.attrs} 
      values={rowData.values} 
      id={rowData.field_id} 
      state={state} 
      setState={setState}
      // emissionFunc={emissionFunc}
      // energyFunc={energyFunc}
    />;
  }
  else if( rowData.element === 'input'){
    tableRowInteractiveEl = 
    <Input 
      attrs={rowData.attrs} 
      id={rowData.field_id} 
      state={state} 
      setState={setState}
      // emissionFunc={emissionFunc}
      // energyFunc={energyFunc}
    />;
  }


  const showModal = rowData.notes !== '' || rowData.link !== '';
  const modalButton = <ModalButton field_id={rowData.field_id} />
  const modal = <Modal field_id={rowData.field_id} description={rowData.description} notes={rowData.notes} link={rowData.link}/>

  return (
  <>
    <tr id={'tblRow_' + rowData.field_id} key={'tblRow_' + rowData.field_id}>
      <td className='text-end align-middle'><label for={rowData.field_id}>{rowData.description}</label></td>
      <td>{tableRowInteractiveEl}</td>
      <td>{ showModal ? modalButton : '' }</td>
    </tr>
    { showModal ? modal : '' }
  </>
)};

TableRow.propTypes = {};

TableRow.defaultProps = {};

export default TableRow;
