import React from 'react';


const Modal = ({ field_id, description, notes, link}) => {
  return(
        <div class="modal fade" style={{'height' : 'initial'}} id={"infoModal-" + field_id} tabIndex="-1" role="dialog" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered" style={{ 'padding' : 'unset' }} role="document">
            <div class="modal-content">
              <div class="modal-header">  
                <div class="h5 modal-title">
                  { description }
                </div> 
              </div>
              <div class="modal-body">
                <p style={{whiteSpace: "pre-wrap"}}>{ notes }</p>
                {link ? <a href={link} target="_blank" rel="noopener noreferrer">{link}</a> : '' }
              </div> 
              <div class="modal-footer">
                <button type="button" class="close btn btn-primary" data-bs-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">Close</span>
                </button>
              </div>
            </div>
          </div>
        </div>
  );

}

export default Modal;
