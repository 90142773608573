import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfo } from '@fortawesome/free-solid-svg-icons';

const ModalButton = ({ field_id }) => {
  return(
    <button 
      type="button" 
      style={{'borderColor' : 'white', 'borderWidth' : '2px' }} 
      class="btn btn-secondary" 
      data-bs-toggle="modal" 
      data-bs-target={"#infoModal-" + field_id}
      aria-label="More Info">
        
        <FontAwesomeIcon icon={faInfo} size='sm'/>

    </button>
  );

}

export default ModalButton;