import React from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';


const Input = ( {id, attrs, state, setState}) => {

  const handleChange = (event) => {
    const value = event.target.value
    setState(prevState => {
      let pState = { ...prevState }
      pState[id] = value
      return pState
    })
  }
  return(
    <input className="form-control" id={id} {...attrs} onChange={handleChange} value={state[id]}></input>
  )
}

export default Input;
