import React from 'react';
import DirectCalculator from '../DirectCalculator/DirectCalculator.jsx'
import IndirectCalculator from '../IndirectCalculator/IndirectCalculator.jsx'


const Calculator = ({
  directVars,
  setDirectVars,
  heatingEmissionFunction,
  heatingEnergyFunction,
  electricEmissionFunction,
  electricEnergyFunction,
  transportationEmissionFunction,
  transportationEnergyFunction,
  indirectVars,
  setIndirectVars,
  dietFunction,
  consumptionFunction,
  servicesFunction,
  totalEmissions,
}) => {

  
  return (
    <div className="calculator">
      <DirectCalculator 
        directVars={directVars} 
        setDirectVars={setDirectVars} 
        heatingEmissionFunction={heatingEmissionFunction} 
        heatingEnergyFunction={heatingEnergyFunction}
        electricEmissionFunction={electricEmissionFunction}
        electricEnergyFunction={electricEnergyFunction}
        transportationEmissionFunction={transportationEmissionFunction}
        transportationEnergyFunction={transportationEnergyFunction}
        totalEmissions={totalEmissions}
      />
      <IndirectCalculator 
        indirectVars={indirectVars} 
        setIndirectVars={setIndirectVars} 
        dietFunction={dietFunction} 
        consumptionFunction={consumptionFunction}
        servicesFunction={servicesFunction}
        totalEmissions={totalEmissions}
      />
    </div>
  )
};

Calculator.propTypes = {};

Calculator.defaultProps = {};

export default Calculator;
