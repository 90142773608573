import React from 'react';
import { PieChart } from '@mui/x-charts';
import GraphSummary from '../GraphSummary/GraphSummary';



const Graph = ( { heating, electric, transportation, diet, consumption, services, totalEmissions } ) => {
  
  const maxFootprint = 25;
  const maxRadius = 175;
  const minRadius = 75;

  let outerRadiusVal = totalEmissions / maxFootprint * maxRadius * .9;
  
  if(outerRadiusVal > maxRadius ){
    outerRadiusVal = maxRadius
  }
  else if( outerRadiusVal < minRadius){
    outerRadiusVal = minRadius
  }
  let innerRadiusVal = outerRadiusVal / 4 ;

  return (
    <div className="chart d-flex position-sticky card">
      <div className="card-body d-flex flex-column align-items-center">
        <h2 className='underline card-title'>Personal Carbon Footprint Breakdown</h2>
        <PieChart 
          margin={{ top: 100, bottom: 100, left: 100, right:100 }}
          series={[
            {
              arcLabel: (item) => `${(item.value / totalEmissions * 100).toFixed(0)} %`,
              arcLabelMinAngle: 45,

              data: [
                { id: 0, value: heating, label: 'Heat'  },
                { id: 1, value: electric, label: 'Electric' },
                { id: 2, value: transportation, label: 'Transportation' },
                { id: 3, value: diet, label: 'Diet' },
                { id: 4, value: consumption, label: 'Consumption' },
                { id: 5, value: services, label: 'Services' }
              ],
              innerRadius: innerRadiusVal,
              outerRadius: outerRadiusVal,
              paddingAngle: 5,
              cornerRadius: 5,
              highlightScope: { faded: 'global', highlighted: 'item' },
              faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
            },
          ]}
          slotProps={{
            legend: {
              direction: 'row',
              position: { vertical: 'bottom', horizontal: 'middle' },
              padding: 0,
            },
          }}
          
          width={2 * maxRadius}
          height={2 * outerRadiusVal + 150}
        />
        <GraphSummary 
          heating={heating} 
          electric={electric} 
          transportation={transportation} 
          diet={diet} 
          services={services}
          consumption={consumption} 
          totalEmissions={totalEmissions}
        />
      
      </div>
    </div>
  );
}

Graph.propTypes = {};

Graph.defaultProps = {};

export default Graph;
