import React, { useState } from "react";
import PropTypes from "prop-types";

const Select = ({ id, attrs, values, state, setState, func }) => {
  
  const handleChange = (event) => {
    const value = event.target.value

    setState(prevState => {
      let pState = { ...prevState }
      pState[id] = value
      return pState
    })
  }
  return (
    <select className="form-select" id={id} {...attrs} defaultValue={state[id]} onChange={handleChange}>
      
      {Object.keys(values).map((key, index) => {
        return (
          <option value={values[key]} >
            {key}
          </option>
        );
      })}
    </select>
  );
};

Select.propTypes = {};

Select.defaultProps = {};

export default Select;
