import Calculator from "../components/Calculator/Calculator";
import Graph from "../components/Graph/Graph";
import CompareGraphs from "../components/CompareGraphs/CompareGraphs";

function Home( {
  directVars,
  setDirectVars,
  heatingEmissionFunction,
  heatingEnergyFunction,
  electricEmissionFunction,
  electricEnergyFunction,
  transportationEmissionFunction,
  transportationEnergyFunction,
  indirectVars,
  setIndirectVars,
  dietFunction,
  consumptionFunction,
  servicesFunction,
  totalEmissions,
} ) {

  return (
    <div className="App">
      <div className="text-start header container py-3">
        <h1 className="d-inline-block">Carbon footprint calculator</h1>
      </div>
      <div className="container py-3">
        <div id="home-text" className="" >
          <p className="text-start">
            This calculator is designed to estimate the user's carbon
            footprint with minimal data. It is a teaching tool for
            comparisons and "what if" scenarios. This calculator is
            not intended to be perfect but is designed for ease of
            use and relative accuracy. The calculations, constants,
            and assumptions are specific to the United States. A
            more precise carbon footprint is a much more complex
            process requiring many energy use inputs.
          </p>
          <p className="text-start">
            Questions like "What is better for my carbon footprint -
            flying or driving" or "What is the carbon footprint of my
            diet?" or "How much will my carbon footprint be
            reduced if I bus instead of drive my car?" All these are
            really good questions that can be "ballparked" using
            this calculator. 
            Note: If you know  your annual energy use (gasoline, kWh or natural gas) you can adjust your direct inputs to match your actual energy use  data."
          </p>
        </div>
        
      </div>
      <div className="container py-5">
        <div className="grid">
          <div className="row">
            <div className="col-md">
              <Calculator 
                directVars={directVars} 
                setDirectVars={setDirectVars} 
                heatingEmissionFunction={heatingEmissionFunction} 
                heatingEnergyFunction={heatingEnergyFunction}
                electricEmissionFunction={electricEmissionFunction}
                electricEnergyFunction={electricEnergyFunction}
                transportationEmissionFunction={transportationEmissionFunction}
                transportationEnergyFunction={transportationEnergyFunction}
                indirectVars={indirectVars}
                setIndirectVars={setIndirectVars}
                dietFunction={dietFunction}
                consumptionFunction={consumptionFunction}
                servicesFunction={servicesFunction}
                totalEmissions={totalEmissions}
              />
            </div>
            <div className="col-md">
              <Graph 
                heating={heatingEmissionFunction()} 
                electric={electricEmissionFunction()} 
                transportation={transportationEmissionFunction()}
                diet={dietFunction()}
                consumption={consumptionFunction()}
                services={servicesFunction()}
                totalEmissions={totalEmissions}
              />
            </div>
          </div>
          <div className="row py-4 justify-content-center">
            <h2 className="underline w-50">Global comparisons</h2>
            <p className="text-start pt-4">
              As of 2022 the US average emissions are approximately 14 mtCO2e/year/person (not 
              including land use changes). (<a href="https://ourworldindata.org/co2-emissions">Our World in Data</a>) 
              The per capita emissions from the world are about 4.5 mtCO2e/year/person (not including 
              land use changes).
            </p>
          </div>
          <div className="row">
            <CompareGraphs usAverage={14} user={totalEmissions} globalAverage={5} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
