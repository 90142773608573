import React from 'react';
import { BarChart } from '@mui/x-charts';


const CompareGraphs = ({usAverage, user, globalAverage}) => {
  return(
    <div className="container">
      <BarChart
        yAxis={[{ scaleType: 'band', data: ['US Average', 'Your Usage', 'Global Average'] }]}
        series={[{ data: [usAverage, user, globalAverage], color: '#43a6f7', label: 'mt CO2e/year' }]}
        height={400}
        margin={{
          left: 160,
          right: 50,
          top: 80,
          bottom: 80,
        }}
        layout="horizontal"
      />
    </div>
  );
  }

CompareGraphs.propTypes = {};

CompareGraphs.defaultProps = {};

export default CompareGraphs;
