import React from 'react';
import Table from '../Table/Table';
import PropTypes from 'prop-types';

const indirectHtmlObj = require("../../data/IndirectTableData.json");

const IndirectCalculator = ({
  indirectVars,
  setIndirectVars,
  dietFunction,
  consumptionFunction,
  servicesFunction,
  totalEmissions,
}) => {

  return(
    <div className="indirect-calculator container grid">
      <h2 className="underline">{indirectHtmlObj.title}</h2>
      <p className="text-start py-3">
        Indirect energy is that energy embedded in our food, the stuff we buy, or our infrastructure (e.g. houses, roads,  buildings).
      </p>
      {indirectHtmlObj.tables.map((tblData, index) => {

        let func;
        switch( tblData.table_id ){
          case "diet" :
            func = dietFunction;
            break;
          case "consumption" : 
            func = consumptionFunction;
            break;
          case "services" :
            func = servicesFunction;
            break;
          default:
            func = dietFunction;
        }

        return (
          <div className="mt-3">
          <Table
            tblData={tblData}
            id={tblData.table_id}
            key={tblData.table_id}
            state={indirectVars}
            setState={setIndirectVars}
            emissionFunc={func}
            totalEmissions={totalEmissions}
          />
          </div>
        );
      })}
    </div>
  );
}

IndirectCalculator.propTypes = {};

IndirectCalculator.defaultProps = {};

export default IndirectCalculator;
