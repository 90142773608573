import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';


const GraphSummary = ({heating, electric, transportation, diet, consumption, services, totalEmissions}) => {
  return(
    <div className='container pt-5'>
      <div className='row'>
        <div className='col-sm'>
          <h3 id='direct-total' className='bg-secondary p-3 h5'>
            Direct
          </h3>
          <p class='h5 px-3' aria-labelledby='direct-total'>{(heating + electric + transportation).toFixed(2) + ' ' + process.env.REACT_APP_EMISSION_UNITS}</p>
        </div>
        <div className='col-sm'>
          <h3 id='indirect-total' className='bg-secondary p-3 h5'>
            Indirect
          </h3>
          <p class='h5 px-3' aria-labelledby='indirect-total'>{(diet + consumption + services).toFixed(2) + ' ' + process.env.REACT_APP_EMISSION_UNITS}</p>
        </div>
        <div className='col-sm'>
          <h3 id='total-emissions' className='bg-secondary p-3 h5'>
            Total
          </h3>
          <p aria-labelledby='total-emissions' class='h5 px-3'>{totalEmissions.toFixed(2) + ' ' + process.env.REACT_APP_EMISSION_UNITS} </p>
        </div>
        <div>
          <Link className='pt-2' to={'/about'}>Calculator references and calculations</Link>
        </div>
      </div>
    </div>
  );
}

GraphSummary.propTypes = {};

GraphSummary.defaultProps = {};

export default GraphSummary;
