import React from "react";

const About = () => {
  return (
    <div className="container pb-6">
      <h1 className="py-3">About the calculator</h1>
      <div>
        <p className="text-start">
          CFcalc.us was designed to estimate personal carbon footprints with minimal 
          user data. An Excel spreadsheet was originally developed for students taking 
          a renewable energy course at the University of Minnesota. 
        </p>
        <p className="text-start">
          CFcalc.us is a teaching tool, not a regulatory tool, used primarily for comparisons 
          and "what if" scenarios. Calculating a more accurate or precise carbon footprint 
          requires many energy use inputs such as the actual kWh of electricity used, the 
          therms of natural gas used, gallons of gasoline purchased, and detailed tracking 
          of all purchases. Most students or renters do not have this information. Because 
          of this, we felt it was better to generalize some of the inputs, not letting the 
          perfect be the enemy of the good. In the end, we feel this calculator is easy to use 
          and relatively accurate.
        </p>
        <p className="text-start">
          Questions like "What is the impact of my transportation on my carbon footprint and 
          how does it compare to my diet? or, "How much will my carbon footprint be reduced 
          if I bike instead of drive my car?" These types of questions can be "ballparked" 
          using this calculator and the results are useful teaching tools. Questions like “Should 
          I buy food that is produced locally?” or “Should I use "paper or plastic bags?" are 
          good questions, but the impact of these choices is dwarfed by the other choices we make. 
          This calculator helps us see the big picture of personal energy use and includes both 
          direct and indirect emissions.
        </p>
        <p className="text-start">
          CFcalc.us evaluates your direct carbon footprint - the carbon emitted from the energy 
          that was purchased directly such as home natural gas, electricity, and gasoline, as 
          well as the indirect carbon emissions.  “Indirect emissions" result from carbon embedded 
          in our food, the stuff we buy, or our infrastructure (e.g. houses, roads, buildings).
        </p>

        <h2 className="py-3">Resources</h2>

        <p className="text-start">
          <li>
            As of 2022, the US average emissions are approximately 14 mtCO2e/year/person. 
            (<a href="https://ourworldindata.org/co2-emissions">Our World in Data</a>)  
            The per capita emissions from the world are about 4.5 mtCO2e/year/person.
          </li>
        </p> 
        <p className="text-start">
          <li>
            CFcalc.us uses a default services emissions of 2.7 Mt/person 
            (<a href='https://shrinkthatfootprint.com/shrink-your-service-footprint'>Shrink That Footprint</a>) to
            to account for the way "per person" emissions are calculated 
            (dividing the total country emissions by the population). Some of these "per person" 
            emissions are therefore not under the control of the individual. This is emissions 
            resulting from products produced in the US that are exported to other countries. 
            In addition, Indirect Emissions include the building of roads, bridges, hospitals, 
            schools and all of the other infrastructure in a country is part of the total country 
            emissions. 
          </li>
        </p>
        <p className="text-start">
          <li>
            For those of you who know your home electricity usage (kWh/year) and natural gas usage 
            (therms per year), you can adjust your behaviors in the model to match your actual 
            energy consumption.
          </li>
        </p>
        <p className="text-start">
          <li>
            Home heating by region and age of dwelling are found at 
            <a href="https://www.eia.gov/consumption/residential/data/2020"> EIA Residential Data 2020</a>
          </li>
        </p>
        <p className="text-start">
          <li>
            Diet carbon emissions come from <a href="https://shrinkthatfootprint.com/shrink-your-food-footprint">Shrink that Footprint-food</a>
          </li>
        </p>
        <p className="text-start">
          <li>
            Consumption Emissions are based on&nbsp; 
            <a href="http://shrinkthatfootprint.com/shrink-your-product-footprint">
               Shrink that Footprint- goods
            </a>
            . Additional assumptions were made to weight the impact of behaviors (e.g. How 
            much do you consume?)
          </li>
        </p>
        <p className="text-start">
          <li>
            Service emissions also come from&nbsp;
            <a href="http://shrinkthatfootprint.com/shrink-your-service-footprint">
              Shrink that Footprint - services 
            </a>
            &nbsp;with assumptions made to account for more or less individual consumption of 
            these services. Note that this value does not vary significantly (+-10%) as 
            this use of services is not a function of consumption or wealth.
          </li>
        </p>
        <p className="text-start">
          <li>
            Carbon equivalents for the calculator are: 5.3 kg CO2/therm for NG and 955 kgCO2e/kWh 
            for electricity in Minnesota. EPA&nbsp;
            <a href="https://www.epa.gov/egrid/power-profiler#/MROW">Power Profiler</a>
          </li>
        </p>
      </div>
    </div>
  );

};

export default About;
